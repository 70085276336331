$(document).ready(function(){
  $('.slider').slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 600,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 3000
  });
  var nav = responsiveNav('.main-nav', { // Selector
    animate: true, // Boolean: Use CSS3 transitions, true or false
    transition: 284, // Integer: Speed of the transition, in milliseconds
    label: 'Menu', // String: Label for the navigation toggle
    insert: 'before', // String: Insert the toggle before or after the navigation
    customToggle: '', // Selector: Specify the ID of a custom toggle
    closeOnNavClick: false, // Boolean: Close the navigation when one of the links are clicked
    openPos: 'static', // String: Position of the opened nav, relative or static
    navClass: 'nav-collapse', // String: Default CSS class. If changed, you need to edit the CSS too!
    navActiveClass: 'js-nav-active', // String: Class that is added to <html> element when nav is active
    jsClass: 'js', // String: 'JS enabled' class which is added to <html> element
    init: function(){}, // Function: Init callback
    open: function(){}, // Function: Open callback
    close: function(){} // Function: Close callback
  });
  $('a[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });
});
